<template>
  <div class="container">
    <div class="head effort">
      <span class="black-text text-darken-3"
        ><strong>Evaluate Subjective Questions</strong></span>
      <div class="dic divider"></div>
    </div>
    <div class="card-panel card">
      <div class="row">
        <div class="col l3 m6 s12 dtitle">
          <strong>Course</strong>
          <select
            class="browser-default dropd"
            @change="onCourseChange($event)"
            v-model="selectedCourseValue"
          >
            <option value="Course" disabled selected>--Select--</option>
            <option
              v-for="course in filterCourse"
              :key="course.courseId"
              :value="course.courseId"
            >
              {{ course.courseName }}
            </option>
          </select>
          <div class="red-text" v-if="showCourseValidation">
            {{ courseValidationMessage }}
          </div>
        </div>
        <!-- <div class="col l3 m6 s12 dtitle">
          <strong>Batch</strong>
          <select
            class="browser-default dropd"
            @change="getDataOfSelectedBatch($event)"
            v-model="selectedBatchValue"
          >
            <option value="Batch" disabled selected>--Select--</option>
            <option
              v-for="batch in filterBatches"
              :key="batch.BatchId"
              :value="batch.BatchId"
            >
              {{ batch.BatchName }}
            </option>
          </select>
          <div v-if="showBatchValidation" class="red-text">
            {{ batchValidationMessage }}
          </div>
        </div> -->
        <div class="col s12 m6 l3 dtitle">
          <strong>Batch</strong>
          <VueMultiselect
            class="browser-default dropd"
            tag-placeholder="Add this Batch"
            v-model="selectedBatchValue"
            placeholder="Search Or Add Batch"
            @update:model-value="updateSelectedBatch"
            label="BatchName"
            track-by="BatchId"
            :options="filterBatches"
            :multiple="true"
            :taggable="true"
            :limit="1"
            @tag="addBatch"
          >
          <template v-slot:beforeList>
            <div>
              <button @click="selectBatchNone">None</button>
              <button @click="selectBatchAll">All</button>
            </div>
          </template>
          </VueMultiselect>
            <div v-if="showBatchValidation" class="red-text">{{ batchValidationMessage }}</div>
        </div>
        <div class="col l3 m6 s12 dtitle">
          <strong>Subject</strong>
          <select class="browser-default dropd" @change="getDataOfSelectedSubject($event)" v-model="selectedSubjectValue">
            <option value="Subject" disabled selected>Select Here</option>
            <option
              v-for="subject in listOfSubjectsForSelectedCourse"
              :key="subject.subjectId" :value="subject.subjectId"
            >
              {{ subject.subjectName }}
            </option>
          </select>
           <div v-if="showSubjectValidation" class="red-text">
            {{ subjectValidationMessage }}
          </div>
        </div>
        <div class="col l3 m6 s12">
          <a
            class="waves-effect waves-light btn submitsbutton"
            @click="getSubjectiveQuestions()"
            >Search</a
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div>
        <table class="qtbl highlight">
          <thead>
            <tr>
              <th>Student Name</th>
              <th>Subject</th>
              <th>No of Questions</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="details in getEvaluateSubjectiveQuestions"
              :key="details.Id"
            >
              <td class="black-text">{{ details.Name }}</td>
              <td class="black-text">{{ details.SubjectName }}</td>
              <td class="black-text">{{ details.TotalQuestions }}</td>
              <td><a @click="get(details.UserId,details.Name, details.SubjectId,)">EVALUATE</a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import Api from "../Api";
import MobileAPI from "../Mobileapi";

export default {
  components: { VueMultiselect },

  name: "EvaluateSubjectiveQuestions",
  data() {
    return {
      Courses: [],
      listOfBatches: [],
      listOfBatchesForSelectedCourse: [],
      listOfSubjectsForSelectedCourse: [],
      SubjectiveQuestions: [],
      selectedCourseValue: -1,
      courseValidationMessage: "",
      selectedBatchValue: [],
      selectedSubjectValue: -1,
      showCourseValidation: false,
      batchValidationMessage: "",
      subjectValidationMessage: "",
      showBatchValidation: false,
      showSubjectValidation: false,
      validationMessageOfDates: "",
      AffiliationCourseId: null,
      SelectedBatchBatchid: null,
      SelectedCourseCourseid: null,
      SelectedSubjectid: null,
      AffiliationId: "",
      BatchId: "",
      CourseId: "",
      SubjectId: "",
      StartDate: "",
      getEvaluateSubjectiveQuestions: [],
      StudentName: [],
      selectedBatches: [],
    };
  },
  computed: {
    filterCourse() {
      return [

        ...new Map(this.Courses.map((item) => [item.courseId, item])).values(),

      ];
    },
    filterBatches() {
      return [

        ...new Map(this.listOfBatchesForSelectedCourse.map((item) => [item.BatchName, item])).values(),

      ];
    },
  },
  activated() {
    if (this.selectedCourseValue !== -1 && this.selectedBatchValue !== -1 && this.selectedSubjectValue !== -1) {
      this.getSubjectiveQuestions();
    }
  },
  created() {
    const user = this.$store.getters.user;
    Api.getDataOfAffiliationCourses(
      {},
      (resp) => {
        this.$store.dispatch("showLoader", false);
        this.Courses = resp.data;
      },
      () => {
        this.$store.dispatch("showLoader", false);
      },
    );
    Api.getBatches(
      user.AffiliationId,
      (response) => {
        this.listOfBatches = response.data;
      },
      () => {
        this.$store.dispatch("showLoader", false);
      },
    );
  },
  methods: {
    addBatch(newTag) {
      const tag = {
        BatchName: newTag,
        BatchId:
          newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.filterBatches.push(tag);
      this.selectedBatchValue.push(tag);
    },
    updateSelectedBatch(value) {
      if (this.filterBatches.length >= 0) {
        this.selectedBatches = [];
      }
      value.forEach((resource) => {
        this.selectedBatches.push(resource);
      });
      const batch = this.selectedBatches.map((a) => a.BatchId);
      this.SelectedBatchBatchid = batch.join(",");
      this.batchValidation = false;
    },
    selectBatchNone() {
      this.selectedBatchValue = [];
    },
    selectBatchAll() {
      this.selectedBatchValue = this.filterBatches;
      this.selectedBatches = this.filterBatches;
      const batch = this.selectedBatches.map((a) => a.BatchId);
      this.SelectedBatchBatchid = batch.join(",");
    },
    onCourseChange(event) {
      this.showCourseValidation = false;
      this.SelectedCourseCourseid = Number(event.target.value);
      this.listOfBatchesForSelectedCourse = this.listOfBatches.filter(
        (batch) => batch.CourseId === Number(event.target.value),
      );
      for (let i = 0; i < this.filterCourse.length; i += 1) {
        if (this.filterCourse[i].courseId === this.SelectedCourseCourseid) {
          this.listOfSubjectsForSelectedCourse = this.filterCourse[i].subjectList;
        }
      }
    },
    getDataOfSelectedBatch(event) {
      this.showBatchValidation = false;
      this.SelectedBatchBatchid = Number(event.target.value);
    },
    getDataOfSelectedSubject(event) {
      this.showSubjectValidation = false;
      this.SelectedSubjectid = Number(event.target.value);
    },
    getSubjectiveQuestions() {
      this.showCourseValidation = false;
      this.showBatchValidation = false;
      this.showSubjectValidation = false;
      if (this.selectedCourseValue === -1) {
        this.showCourseValidation = true;
        this.courseValidationMessage = "Please select course";
      } else if (this.selectedBatchValue === -1) {
        this.showBatchValidation = true;
        this.batchValidationMessage = "Please select Batch";
      } else if (this.selectedSubjectValue === -1) {
        this.showSubjectValidation = true;
        this.subjectValidationMessage = "Please select Subject";
      }

      const user = this.$store.getters.user;
      const data = {
        AffiliationId: Number(user.AffiliationId),
        BatchId: this.SelectedBatchBatchid,
        CourseId: this.SelectedCourseCourseid,
        SubjectId: this.SelectedSubjectid,
        // StartDate: "2022-02-23",

        // AffiliationId: 162,
        // BatchId: 1366,
        // CourseId: 1,
        // SubjectId: 3,
      };
      if (this.selectedCourseValue !== -1 && this.selectedBatchValue !== -1 && this.selectedSubjectValue !== -1) {
        this.$store.dispatch("showLoader", true);
        MobileAPI.getEvaluateSubjectiveQuestions(data, (response) => {
          if (response.data.length === 0) {
            window.M.toast({
              html: "No records found for your current selection. Please try a different selection.",
              classes: "rounded error",
            });
          }
          this.$store.dispatch("showLoader", false);
          this.getEvaluateSubjectiveQuestions = response.data;
        });
      }
    },
    bindSubjectiveQuestions() {
      const user = this.$store.getters.user;
      const data = {
        AffiliationId: Number(user.AffiliationId),
        BatchId: this.SelectedBatchBatchid,
        CourseId: this.SelectedCourseCourseid,
        SubjectId: this.SelectedSubjectid,
      };
      this.$store.dispatch("showLoader", true);
      MobileAPI.getEvaluateSubjectiveQuestions(data, (response) => {
        this.$store.dispatch("showLoader", false);
        this.getEvaluateSubjectiveQuestions = response.data;
        this.StudentName = this.getEvaluateSubjectiveQuestions.Name;
      });
    },
    get(user, name) {
      const data = {
        UserId: user,
        Name: name,
        SubjectId: this.SelectedSubjectid,
        allsubjects: this.listOfSubjectsForSelectedCourse,
      };

      this.$router.push({
        name: "TeacherFeedBack",
        params: {
          userId: data,
          Allsubjects: data.allsubjects,
        },
      });
    },
  },
};
</script>

<style scoped>
.dropd {
    border-radius: 5px;
    height: 36px;
    margin-top: 8px;
  }
.head {
  margin-top: 19px;
  font-size: 22px;
  color: #642c90;
}
.effort {
  margin-top: 19px;
  left: 132px;
  font-size: 22px;
}
.dic {
  margin-top: 18.5px;
  border-radius: 1px solid;
  margin-bottom: 24.5px;
}
.card {
  border-radius: 5px;
  margin-top: 24px;
  background: #ffffff;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
}
.submitsbutton {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  border-radius: 5px;
  font-size: 14px;
  margin-left: 41px;
  margin-top: 33px;
  width: 140px;
  height: 35px;
}
table.qtbl {
  border-collapse: separate;
  border-spacing: 0 10px;
}
table.qtbl thead tr {
  background-color: #6a2f85;
  color: #fff;
}
table.qtbl thead tr th {
  border-radius: 0;
}
table.qtbl thead tr th:first-child,
table.qtbl tbody tr td:first-child {
  border-radius: 5px 0 0 5px;
}
table.qtbl tbody tr {
  cursor: pointer;
  background-color: #fff;
  font-size: 14px;
}
table.qtbl thead tr th:last-child, table.qtbl tbody tr td:last-child {
    border-radius: 0 5px 5px 0;
}
table.qtbl tbody tr td {
    color: #6a2f85
}
.dtitle {
  font-size: 18px;
  color: #333333;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
